import {Link} from "react-router-dom";
import React from "react";

const style = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
    },
    header: {
        textAlign: 'center',
        marginBottom: '40px',
    },
    separator: {
        height: 3,
        width: "50px",
        backgroundColor: 'rgb(222, 5, 12)',
        margin: '20px auto',
    },
    jobGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
        gap: '20px',
        width: '100%',
        maxWidth: '1200px',
        padding: '0 20px',
    },
    card: {
        backgroundColor: '#fff',
        borderRadius: '10px',
        border: "#008df3 solid 1px",
        padding: '20px',
        textAlign: 'left',
        transition: 'transform 0.2s, box-shadow 0.2s',
        height: '400px', // Fixed height for the card
        display: 'flex',
        flexDirection: 'column',
    },
    fieldContainer: {
        marginBottom: '15px',
    },
    title: {
        fontWeight: 'bold',
        fontSize: '18px',
        marginBottom: '5px',
        flex: '1 0 15%', // Title takes 15% of the card height
    },
    location: {
        fontSize: '18px',
        color: '#333',
        marginBottom: '15px',
        flex: '1 0 10%', // Location takes 10% of the card height
    },
    overview: {
        fontSize: '16px',
        color: '#555',
        lineHeight: '1.5',
        flex: '1 0 55%', // Overview takes 55% of the card height
        overflow: 'hidden', // Hide overflow if content is too long
        textOverflow: 'ellipsis', // Add ellipsis for overflowing text
        display: '-webkit-box',
        WebkitLineClamp: '3', // Limit to 3 lines
        WebkitBoxOrient: 'vertical',
    },
    button: {
        backgroundColor: 'rgb(222, 5, 12)',
        color: '#fff',
        padding: '10px 20px',
        borderRadius: '5px',
        border: 'none',
        fontSize: '16px',
        cursor: 'pointer',
        textAlign: 'center',
        flex: '1 0 10%', // Button takes 10% of the card height
        marginTop: 'auto', // Push button to the bottom
    },
    link: {
        textDecoration: 'none',
    },

    label:{
        color:'rgb(222, 5, 12)',
        fontWeight: 'bold',
        fontSize: '18px',
    }
};

export default ({ jobs }) => {
    return (
        <div style={style.container}>
            <div style={style.header}>
                <h2>Explore Careers at Longhorn Associates</h2>
                <p>Find the right position for you and take your career to the next level.</p>
                <div style={style.separator} />
            </div>

            <div style={style.jobGrid}>
                {jobs.map(job => (
                    <div key={job.id} style={style.card}>
                        {/* Position */}
                        <div style={style.fieldContainer}>
                            <span style={style.label}>Position:</span>
                            <h3 style={style.title}>{job.position}</h3>
                        </div>

                        {/* Location */}
                        <div style={style.fieldContainer}>
                            <span style={style.label}>Location:</span>
                            <h4 style={style.location}>{job.location}</h4>
                        </div>

                        {/* Job Overview */}
                        <div style={style.fieldContainer}>
                            <span style={style.label}>Overview:</span>
                            <p style={style.overview}>{job.jobOverview}</p>
                        </div>

                        {/* View Details Button */}
                        <Link to={`/job?id=${job.position}`} style={style.link}>
                            <button style={style.button}>View Details</button>
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    );
};
