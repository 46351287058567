import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import jobs from '../jobs';

const style = {
    main: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        flexGrow: 1,
        padding: '20px',
        backgroundColor: '#f4f4f4',
        minHeight: 500
    },
    backButton: {
        display: "flex",
        alignItems: "center",
        backgroundColor: "rgb(222, 5, 12)",
        borderRadius: "20px",
        border: "none",
        color: "white",
        padding: '10px 20px',
        textDecoration: 'none',
        marginBottom: '20px',
        fontSize: '16px',
        fontWeight: 'bold',
    },
    buttonText: {
        marginLeft: '10px',
        fontSize: '16px',
    },
    jobDetails: {
        maxWidth: '800px',
        width: '100%',
        padding: '20px',
        borderRadius: '10px',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        backgroundColor: '#ffffff',
        marginBottom: '20px',
    },
    jobTitle: {
        fontSize: '32px',
        marginBottom: '10px',
        color: '#333',
        fontWeight: 'bold',
    },
    jobLocation: {
        fontSize: '18px',
        color: '#666',
        marginBottom: '20px',
    },
    sectionTitle: {
        fontSize: '24px',
        color: '#333',
        marginBottom: '10px',
        fontWeight: 'bold',
    },
    sectionContent: {
        fontSize: '16px',
        color: '#555',
        marginBottom: '20px',
        lineHeight: '1.6',
        textAlign: 'left', // Align text to the left
    },
    separator: {
        height: '4px',
        backgroundColor: 'rgb(222, 5, 12)',
        width: '50%',
        margin: '20px auto',
        borderRadius: '2px',
    },
    applyButton: {
        display: 'inline-block',
        padding: '10px 20px',
        borderRadius: '5px',
        backgroundColor: 'rgb(222, 5, 12)',
        color: 'white',
        textDecoration: 'none',
        fontSize: '16px',
        fontWeight: 'bold',
        marginTop: '20px',
    },
    label: {
        fontWeight: 'bold',
        color: '#333',
    },
    list: {
        paddingLeft: '20px', // Add padding to the left of the list
        margin: 0,
    },
    listItem: {
        marginBottom: '10px', // Add margin to space out list items
        listStyleType: 'disc', // Use disc bullets
        color: '#555', // Color for list items
    }
};

export default () => {

    const location = useLocation();
    const id = new URLSearchParams(location.search).get('id');
    const job = jobs.find(job => job.position === id);

    if (!job) {
        return (
            <section style={style.main}>
                <Link to="/careers" style={style.backButton}>
                    &larr; Back to Careers
                </Link>
                <p style={{fontSize: '18px', color: '#666'}}>Job not found</p>
            </section>
        );
    }

    return (
        <section style={style.main}>
            <div style={style.jobDetails}>
                <h1 style={style.jobTitle}>{job.position}</h1>
                <p style={style.jobLocation}>{job.location}</p>
                <div style={style.separator}/>

                <div style={style.sectionContent}>
                    <h2 style={style.sectionTitle}>Job Overview</h2>
                    <p>{job.jobOverview}</p>
                </div>

                <div style={style.sectionContent}>
                    <h2 style={style.sectionTitle}>Key Responsibilities</h2>
                    <ul style={style.list}>
                        {job.keyResponsibilities.map((responsibility, index) => (
                            <li key={index} style={style.listItem}>{responsibility}</li>
                        ))}
                    </ul>
                </div>

                <div style={style.sectionContent}>
                    <h2 style={style.sectionTitle}>Requirements</h2>
                    <ul style={style.list}>
                        {job.requirements.map((requirement, index) => (
                            <li key={index} style={style.listItem}>{requirement}</li>
                        ))}
                    </ul>
                </div>

                <p style={style.sectionContent}><span
                    style={style.label}>Application Deadline:</span> {job.applicationDeadline}</p>


                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <Link to="/careers" style={style.applyButton}>
                        &larr; Back to Careers
                    </Link>
                    <a href={`mailto:${job.applyTo}`} style={style.applyButton}>Apply Now</a>


                </div>

            </div>

        </section>
    );
};
