const jobs = [
    {
        id:1,
        position: "Business Development Officer",
        location: "Lusaka",
        jobOverview: "As a Business Development Officer, you will play a crucial role in driving sales and expanding our market presence in the Province. You will be responsible for identifying new business opportunities, maintaining relationships with existing clients, and achieving sales targets.",
        keyResponsibilities: [
            "Implement business development strategies to achieve branch goals and targets.",
            "Identify market trends and opportunities to make strategic decisions.",
            "Analyze competitor activity and adjust strategies to maintain a competitive edge.",
            "Participate in developing short and long-term marketing strategy and operational plans.",
            "Participate in developing procedures and policies for better handling of customer correspondence and complaints.",
            "Identify and engage potential clients to expand the branch’s client base.",
            "Build and maintain strong relationships with existing clients to ensure long-term satisfaction.",
            "Drive sales initiatives to meet or exceed targets for the branch.",
            "Develop and execute marketing campaigns and promotions to attract new business.",
            "Monitor sales performance and adjust strategies as needed to achieve goals.",
            "Lead, motivate, and supervise the branch team to ensure high performance.",
            "Provide training and development opportunities to enhance team skills and knowledge.",
            "Conduct performance evaluations and address team challenges.",
            "Ensure compliance with company standards and industry best practices.",
            "Ensure high levels of customer service and address issues promptly.",
            "Implement processes to enhance customer satisfaction.",
            "Track and analyze branch performance metrics, including sales and client acquisition.",
            "Prepare reports to the Chief Marketing Officer on branch performance.",
            "Explore and develop strategic partnerships to enhance business opportunities."
        ],
        requirements: [
            "Degree in Business Administration, Marketing, or related discipline.",
            "Minimum 4 years of experience, preferably in the financial sector.",
            "Proven track record of successful marketing strategies that drive sales.",
            "Excellent communication, negotiation, and relationship-building skills.",
            "Creative and strategic thinking with a focus on innovation.",
            "Experience managing budgets and delivering on KPIs.",
            "Willingness to travel and attend functions as required."
        ],
        applicationDeadline: "27th September 2024",
        applyTo: "careers@longhorn-associates.com"
    },
    {
        id:2,
        position: "Administrative Assistant",
        location: "Kitwe",
        jobOverview: "As an Administrative Assistant, you will provide administrative support to ensure efficient office operations and handle queries in person, by phone, or email.",
        keyResponsibilities: [
            "Provide administrative support for efficient office operations.",
            "Handle queries in person, by phone, or email, following up on payment queries.",
            "Manage calendars for senior staff members.",
            "Draft and send emails, letters, and other communications.",
            "Update client information in databases and spreadsheets.",
            "Prepare meeting agendas, take minutes, and send meeting notices.",
            "Operate and maintain office equipment.",
            "Work closely with other administrative staff and colleagues.",
            "Ensure claims are processed efficiently per company policies.",
            "Maintain accurate records of claim documents and correspondence.",
            "Keep an up-to-date filing system for all documents.",
            "Ensure the Front Office is maintained and professional.",
            "Replenish office supplies and manage petty cash.",
            "Explain pension, gratuity, and unit trust details to clients.",
            "Investigate unpaid pensions and redemptions to resolve issues.",
            "Compile weekly and monthly reports for submission to supervisors.",
            "Handle other tasks as assigned."
        ],
        requirements: [
            "Diploma in Business Administration or equivalent.",
            "At least 2 years of experience in a receptionist or administrative role.",
            "Proficient in Microsoft Office Suite (Word, Excel, Outlook, PowerPoint).",
            "Team player with a positive attitude and adaptability.",
            "Critical thinker and problem-solving skills.",
            "Good time-management skills.",
            "Excellent communication, interpersonal, and customer service skills.",
            "Able to work with minimal supervision."
        ],
        applicationDeadline: "27th September 2024",
        applyTo: "careers@longhorn-associates.com"
    },
];

export default jobs;



