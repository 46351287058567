import React from 'react'
import {Route, Routes} from "react-router-dom";
import Home from '../../src/pages/home';
import Apartments from '../../src/pages/apartments';
import Services from '../../src/pages/services';
import ServiceDetails from '../../src/pages/services/serviceDetails';
import ContactUs from '../../src/pages/contact';
import Team from '../../src/pages/team';
import NotFound from '../../src/pages/404';
import Member from '../../src/pages/team/member';
import About from '../../src/pages/about';
import Careers from '../../src/pages/careers';
import Job from '../../src/pages/careers/job';
import Forms from '../../src/pages/forms';
import Backend from '../../src/pages/backend';
import Auth from '../../src/pages/auth';
import PrivateRoute from "../components/protectedRoute";

// Assume this is your authentication status
const isLoggedIn = false; // Change this based on your logic



export default () =>
    <Routes>
        <Route exact path="/" element={<Home/>}/>
        <Route exact path="/contact" element={<ContactUs/>}/>
        <Route exact path="/property" element={<Apartments/>}/>
        <Route exact path="/services" element={<Services/>}/>
        <Route exact path="/service" element={<ServiceDetails/>}/>
        <Route exact path="/team" element={<Team/>}/>
        <Route exact path="/member" element={<Member/>}/>
        <Route exact path="/job" element={<Job/>}/>
        <Route exact path="/careers" element={<Careers/>}/>
        <Route exact path="/about" element={<About/>}/>
        <Route exact path="/forms" element={<Forms/>}/>
        <Route exact path="/login" element={<Auth/>}/>

        <Route element={<PrivateRoute/>}>
            <Route exact path="/backend" element={<Backend/>}/>
        </Route>

        {/* Route for unmatched routes */}
        <Route path="*" element={<NotFound/>}/>

        {/*<Route*/}
        {/*    exact*/}
        {/*    path="/backend"*/}
        {/*    element={isLoggedIn ? <Backend /> : <Navigate to="/login" />}*/}
        {/*/>*/}
    </Routes>
